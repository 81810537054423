import React, { useEffect } from "react";

export const Newsletter = ({ title, content, color, portalId, formId }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        });
      }
    });
  }, [portalId, formId]);

  return (
    <section className="newsletter">
      <div className={`bg-${color} ${title ? 'py-5' : 'pb-5'}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-md-left mb-4 mb-md-0">
              {title && <h2 className="mb-2 text-center ">{title}</h2>}
              {content && <p className="text-center">{content}</p>}
              <div className="row justify-content-center">
                <div className={`col-md-10 col-lg-8 text-center text-md-left mb-4 mb-md-0 ${title ? 'py-4' : 'pb-lg-5'}`}>
                  <div id="hubspotForm" className="text-center">
                    Loading newsletter form...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;

import React from "react";
import Image from "../Hacks/Image";

export const LogoLockup = ({ title, logos, fullHeight }) => {
  var size = 1;
  if (logos && logos.length < 7) {
    size = 2;
  }
  return (
    <section
      className={`logoLockup container-fluid ${
        fullHeight ? "logoLockup-fullHeight" : "logoLockup-restrictedHeight"
      }`}
    >
      <div className="my-lg-5 row contained-xl justify-content-center text-center">
        <div className="col-lg-10 col-xl-8">
          {title && <h2 className="h4 text-secondary">{title}</h2>}
          <div className="mt-4 row justify-content-center p-3">
            {logos &&
              logos.map(function (logo, i) {
                return (
                  <div
                    className={"img-container col-4 col-md-" + size + " px-4 align-self-center h-100 mb-3"}
                    key={"logo" + i}
                  >
                    {logo && (
                      <Image
                        image={logo}
                        className="w-100"
                        alt=""
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoLockup;

import React from "react";
import { AttentionSeeker, Fade } from "react-awesome-reveal";

const HowDoesItWork = ({ title, intro, timeline }) => {
  return (
    <section id="howitworks" className="bg-white pt-5 mt-lg-5 px-3 px-lg-0">
      <Fade>
        <h2 className="text-center font-family-display">{title}</h2>
        <p className="lead text-center">{intro}</p>
      </Fade>
      <div className="container rounded-lg pt-4 px-lg-4">
        <div className="row justify-content-center pt-4 py-lg-5">
          <div className="col-11 ">
            <div className="main-timeline">
              {timeline &&
                timeline.map(function (event, i) {
                  return (
                    <div
                      key={`event-${i}`}
                      className={`timeline ${
                        event.left_or_right === "RIGHT" ? "timeline-right" : ""
                      } pb-3 py-md-3 py-lg-4`}
                    >
                      <div className="timeline-line"></div>

                      <Fade>
                        <span className="timeline-icon">
                          <AttentionSeeker>
                            <span>{event.icon}</span>
                          </AttentionSeeker>
                        </span>
                      </Fade>
                      <Fade delay={200}>
                        <div className="timeline-content-title h5 font-weight-bold">
                          <span>{event.title}</span>
                        </div>
                      </Fade>
                      <Fade delay={400}>
                        <div className="timeline-content">
                          <div
                            className={`title mb-1 text-${event.accent} font-family-display`}
                          >
                            {event.contentTitle}
                          </div>
                          <p className="description">{event.contentContent}</p>
                          <p className="small font-italic">
                            {event.contentSub}
                          </p>
                        </div>
                      </Fade>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowDoesItWork;

import React from 'react'
// import Image from '../Hacks/Image'
import Content from '../Hacks/Content'
import { Link } from 'gatsby'



export const GeneralContent = ({
  backgroundColour,
  colour,
  full_width,
  content,
  pull_link_title,
  pull_link_content,
  pull_link_read_more,
  alignment
}) => {

  if (pull_link_content) {
    if (pull_link_content.term_acf) {
      colour = `bg-${pull_link_content.term_acf.category_colour}`
    }
  }

  return (
    <section className={`journal_content__text  py-5 ${backgroundColour && (`bg-`+backgroundColour)} ${alignment && (`text-`+alignment)}`}>
      <div className={`${full_width==="true" ? 'container-fluid' : 'container'}`}>
        <div
          className="news-inner row justify-content-center contained-xl py-lg-5"
          data-aos="fade-in"
        >
          <Content
            className={full_width==="true" ? `col-lg-10` : `col-lg-10`}
            content={content}
          />
        </div>
        {pull_link_content && (
          <aside className={`journal_content__pulllink ${colour}`}>
            <div className="journal_content__pulllink_content">
              <div className="heading">{pull_link_title}</div>
              <h3 className="h5 text-left">{pull_link_content.post_title}</h3>
              <Link
                to={`/blog/${pull_link_content.permalink}`}
                className="btn btn-sm btn-primary stretched-link"
              >
                {pull_link_read_more}
              </Link>
            </div>
          </aside>
        )}
      </div>
    </section>
  )
}

export default GeneralContent

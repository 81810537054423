import React from "react";
import { Link } from "gatsby";
//import Fade from "react-reveal/Fade";
import Content from "../Hacks/Content";

export const SidedList = ({
  cards,
  title,
  intro,
  numbers,
  backgroundColour,
  ctaText,
  ctaLink,
  columns,
}) => {
  return (
    <section
      className={`sidedlist bg-${backgroundColour} pt-5 pt-lg-0 pb-lg-5`}
    >
      <div className="container-fluid py-lg-5">
        <div className="row contained-xl justify-content-center pt-lg-5">
          <div className="col-md-10 col-lg-3 mb-5">
            <h2
              className={`${
                columns === "2" ? "display-lg-3 mt-0" : "display-lg-5"
              } ${
                backgroundColour === ("dark-primary" || "primary")
                  ? "text-secondary"
                  : ""
              }`}
            >
              {title}
            </h2>
            <Content content={intro} />
            {ctaLink && (
              <Link href={ctaLink} className="btn btn-secondary btn-sm mt-4">
                {ctaText}
              </Link>
            )}
          </div>

          <div className="col-md-10 col-lg-6 offset-lg-1">
            <div className="row contained-xl justify-content-center">
              {cards && cards.map(function (card, i) {
                return (
                  // <Fade>
                  <div
                    className={`col-md-${12 / parseInt(columns)} mb-5`}
                    key={`list-item-${i}`}
                  >
                    <div className="h-100">
                      <h3 className={`h4 mb-2`}>
                        {(numbers === "true" || numbers === true) && (
                          <React.Fragment>
                            <span className="text-secondary">{i + 1}.</span>{" "}
                          </React.Fragment>
                        )}
                        {card.subtitle && (
                          <React.Fragment>
                            <i
                              className={card.subtitle + ` mr-1 text-secondary`}
                            ></i>{" "}
                          </React.Fragment>
                        )}
                        {card.title}
                      </h3>
                      <Content content={card.content} />
                    </div>
                  </div>
                  // </Fade>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SidedList;

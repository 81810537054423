import React from "react";
import ReactPlayer from "react-player";

export const Video = ({
  options,
  thumbnail,
  full_width,
  video,
  caption,
  controls,
  muted,
  playing,
}) => {
  if (!options) {
    options = [];
  }

  return (
    <section className="video my-5">
      <div className={full_width === "true" ? "container-fluid" : "container"}>
        <div className="news-inner row justify-content-center contained-xl">
          <div className={full_width === "true" ? "col-lg-10" : "col-lg-8"}>
            <div className="shadow-lg rounded-sm embed-responsive embed-responsive-16by9">
              <ReactPlayer
                url={video}
                controls={controls}
                className="react-player embed-responsive-item"
                width="100%"
                height="100%"
                playing
                light={thumbnail}
                muted={muted}
                playsinline={true}
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
              />
            </div>
            {caption && <p className="small text-center mt-3">{caption}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;

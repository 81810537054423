import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";
import Content from "../Hacks/Content";

export default class HeroIntro extends React.Component {
  render() {
    const {
      headerType,
      subtitle,
      title1,
      title2,

      massiveHero,

      excerpt,
      content,

      ctaLink,
      ctaText,
      cta2Link,
      cta2Text,

      image1,
    } = this.props;
    // var classes=false;

    return (
      <header
        className={`hero container-fluid pt-3 mt-0 py-5 pb-lg-0 mt-5 mt-lg-0 mt-xl-5`}
      >
        <div
          className={`row justify-content-center contained-xl 
            ${headerType === "LEFT" ? "pb-lg-5" : ""}`}
        >
          <div
            className={`
              pt-lg-3
              align-self-center
              ${
                headerType === "LEFT"
                  ? "col-10 col-md-7 col-lg-5 order-lg-2"
                  : "col-10 col-md-7 col-lg-5"
              }
            `}
          >
            {image1 && (
              <Image image={image1} className="w-100 drop-shadow-sm" alt="" />
            )}
          </div>

          {headerType === "CENTER" && <div className="col-12"></div>}

          <div
            className={`align-self-center ${
              headerType === "LEFT"
                ? "col-10 col-lg-5 text-center text-lg-left pb-lg-5 mb-md-5 "
                : "col-10 col-lg-6 text-center"
            } pt-lg-5 pr-lg-3 order-lg-1 `}
          >
            {subtitle ? (
              <React.Fragment>
                <h1
                  className={`h4 mt-2 mb-0 mb-lg-2 text-gray ${
                    headerType === "LEFT" ? "pr-lg-5" : ""
                  }`}
                >
                  {subtitle}
                </h1>
                {(title2 || title1) && (
                  <h2 className="h1">
                    {title1 && <div>{title1}</div>}
                    {title2 && <div className={`text-secondary`}>{title2}</div>}
                  </h2>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {(title2 || title1) && (
                  <h1
                    className={`${
                      massiveHero === "true" || massiveHero === true
                        ? "display-lg-3"
                        : "h2"
                    }`}
                  >
                    {title2 && <Content content={title2} />}
                    {title1 && (
                      <Content className={`text-secondary`} content={title1} />
                    )}
                  </h1>
                )}
              </React.Fragment>
            )}

            {excerpt && (
              <Content
                className={`${headerType === "LEFT" ? "p" : "lead"}`}
                content={excerpt}
              />
            )}
            {content && (
              <Content
                className={`${headerType === "LEFT" ? "p" : "lead mb-3"}`}
                content={content}
              />
            )}

            {ctaLink && (
              <Link
                to={ctaLink}
                className="btn btn-danger mt-3 mt-3 btn-lg main-cta ml-2 ml-lg-0 mr-2"
              >
                {ctaText}
              </Link>
            )}
            {cta2Link && (
              <Link
                to={cta2Link}
                className={`btn btn-outline-danger mt-1 mt-3 btn-lg ml-2 ml-lg-0 main-cta mr-2`}
              >
                {cta2Text}
              </Link>
            )}
          </div>
        </div>
      </header>
    );
  }
}

// TODO: bring back categories once we have the data sorted
// {categories[0] && (
//   <div className="col-lg-4 my-md-2 offset-md-2 text-md-right mb-5 py-lg-5">
//     <div className="dropdown">
//       <a
//         className="btn btn-outline-primary"
//         href="#"
//         role="button"
//         id="dropdownMenuLink"
//         data-toggle="dropdown"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         Filter the Journal <i className="fa fa-caret-down"></i>
//       </a>
//       <div
//         className="dropdown-menu"
//         aria-labelledby="dropdownMenuLink"
//       >
//         {categories.map(({ node: category }, index) => (
//           <Link
//             to={'/blog/category/' + category.slug + '/'}
//             className="dropdown-item"
//             key={'category' + index}
//           >
//             {category.name}
//           </Link>
//         ))}
//       </div>
//     </div>
//   </div>
// )}

import React from "react";
import Image from "../Hacks/Image";

// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

//import Fade from "react-reveal/Fade";
// import Pulse from "react-reveal/Pulse";

import { Fade } from "react-awesome-reveal";
// import ReactPlayer from "react-player";

export const HomeHero = ({
  intro_subheader,
  intro_hello,
  intro_title,
  intro_intro,
  ctaLink,
  ctaText,
  cta2Link,
  cta2Text,
  intro_image,
  testimonial_image,
  testimonial_from,
  testimonial,
  logo_title,
  logos,
}) => {
  return (
    <React.Fragment>
      <header id="hero" className="hero hero_home hero-alt3 pb-0 pt-5">
        <div className="position-relative container-fluid">
          <div className="row justify-content-center contained-xl py-md-4 pt-lg-5">
            <div className="order-2 order-lg-1 col-11 col-md-10 col-lg-5 col-xl-5 pt-lg-5 my-md-2 text-center text-lg-left floaty z-index-100">
              <h1 className="display-2 mb-4">
                <Fade>
                  <>
                    <span className="">{intro_hello}</span>
                    <span className="text-secondary">{intro_title}</span>
                  </>
                </Fade>
              </h1>

              <Fade delay={100}>
                {/* <p className="lead">And Say hello to your new Lead Distribution System</p> */}
                <div
                  className="lead mb-4"
                  dangerouslySetInnerHTML={{
                    __html: intro_intro,
                  }}
                />
              </Fade>

              <Fade delay={200}>
                <div>
                  {ctaLink && (
                    <Link
                      to={ctaLink}
                      className="btn btn-danger mt-1 mt-3 btn-lg main-cta ml-2 ml-lg-0 mr-2"
                    >
                      {ctaText}
                    </Link>
                  )}
                  {cta2Link && (
                    <>
                      {/* <div className="d-block d-md-none"></div> */}
                      <Link
                        to={cta2Link}
                        className="btn btn-outline-danger mt-1 mt-3 btn-lg ml-2 ml-lg-0 main-cta mr-2"
                      >
                        {cta2Text}
                      </Link>
                    </>
                  )}
                </div>
              </Fade>
            </div>
            <div className="order-1 order-lg-1 col-10 col-md-7 col-lg-5 col-xl-5 ">
              <div className="hero-systemimage floaty mt-2 position-relative home_testimonial_container">
                <div className="floaty" data-scroll-speed-y="-10">
                  {intro_image && (
                    <Fade delay={150}>
                      {/* <div className="demo-video-embed">
                        <ReactPlayer
                          url={`https://vimeo.com/698221855/ee91300c6b`}
                          // vimeoConfig={{
                          //   iframeParams: { background: true, fullscreen: 0 },
                          // }}
                          className={`demo-video-embed-item shadow-lg rounded-lg`}
                          width="100%"
                          height="100%"
                          volume={0}
                          controls={false}
                          playsinline={true}
                          playIcon={
                            <i class="far fa-play-circle text-secondary h1 "></i>
                          }
                          muted={true}
                          light={false}
                          playing={true}
                          loop={true}
                        />
                      </div> */}
                      <Image
                        image={intro_image}
                        loading="eager"
                        alt="System screenshot"
                        className="img-fluid"
                      />
                    </Fade>
                  )}
                </div>
              </div>
            </div>

            {logos && (
              <div className="order-3 order-lg-2 col-lg-12 mt-lg-5">
                <Fade delay={250}>
                  <section
                    id="partners"
                    className="text-md-right text-center container px-0 pt-lg-5"
                  >
                    <h2 className="h5 mt-5 text-center text-uppercase">
                      {logo_title}
                    </h2>
                    <div className="row justify-content-center align-items-center">
                      {logos.map(function (logo, i) {
                        return (
                          <div
                            className="col-4 col-lg-2 mb-3 px-4"
                            key={"partner" + i}
                          >
                            <Image
                              image={logo}
                              backgroundColor="transparent"
                              placeholder="blurred"
                              className="img-fluid w-100"
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </Fade>
              </div>
            )}
          </div>
        </div>
      </header>
      <div id="afterheader"></div>
    </React.Fragment>
  );
};

export default HomeHero;

import React from "react";
import { Fade } from "react-awesome-reveal";
import Image from "../Hacks/Image";

export const Images = ({ images }) => {
  return (
    <section className="container-fluid">
      <div className="row justify-content-center mb-5 px-4 px-lg-0">
        {images.map((image, i) => (
          <div
            key={`image-` + i}
            className={`col-6 col-lg-${image.width} mb-4 ${
              image.marginTop === true ? "mt-lg-5" : ""
            }`}
          >
            <Fade>
              <Image
                image={image.image}
                className="img-fluid rounded-sm shadow-sm"
                alt=""
              />
            </Fade>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Images;

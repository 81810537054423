import React from "react";
// import React, { useRef } from "react";
import Content from "../Hacks/Content";
// import IframeResizer from "iframe-resizer-react";

export const IFrameEmbed = ({
  include_side_content,
  side_title,
  side_content,
  url,
}) => {
  // const iframeRef = useRef(null);
  // // const [open, setOpen] = useState(false)
  // // const [messageData, setMessageData] = useState()

  // const onResized = (data) => {
  //   // setMessageData(data)
  //   console.log(data);
  // };

  // const onMessage = (data) => {
  //   // setMessageData(data)
  //   // iframeRef.current.sendMessage('Hello back from the parent page')
  // };
  return (
    <section className="typeform container-fluid py-lx-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row justify-content-center">
            {include_side_content && (
              <div className="col-lg-3 col-xl-5 mb-5">
                <h2 className="mb-3">{side_title}</h2>
                <Content className="wysiwyg" content={side_content} />
              </div>
            )}
            <div className={`col-lg-10 col-xl-8`}>
              <div className="form pt-5">
                <iframe
                  // forwardRef={iframeRef}
                  // heightCalculationMethod="max"
                  // inPageLinks
                  // log
                  // autoResize={true}
                  // resizeFrom={('parent', 'child')}
                  // onMessage={onMessage}
                  // onResized={onResized}
                  className="rounded-lg p-0"
                  frameBorder="0"
                  title={side_title}
                  src={url}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    height: "100vh",
                    maxHeight: "700px",
                    border: 0,
                    transition: "0.3s ease all",
                    overflow: "hidden",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IFrameEmbed;
